// sizeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const sizeSlice = createSlice({
  name: "size",
  initialState: {
    sizeOptions: [], // Initialize sizeOptions as an empty array
    selectedSizeIndex: null,
  },
  reducers: {
    setSelectedSizeIndex: (state, action) => {
      state.selectedSizeIndex = action.payload;
    },
    saveSizeOptions: (state, action) => {
      state.sizeOptions = action.payload;
    },
  },
});

export const { setSelectedSizeIndex, saveSizeOptions } = sizeSlice.actions;
export const selectSize = (state) => state.size;

export default sizeSlice.reducer;
