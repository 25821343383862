import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark text-white mt-4">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={6} className="text-center py-3">
            <div className="social-icons">
              <a
                href="https://www.facebook.com/hoticeclothingshop"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon mr-md-3"
              >
                <FaFacebook color="#3b5998" size={50} />
              </a>
              <a
                href="https://ca.linkedin.com/company/hot-ice-clothing"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon mr-md-3"
              >
                <FaLinkedin color="#0077b5" size={50} />
              </a>
              <a
                href="https://www.instagram.com/hoticeclothingshop/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon mr-md-3"
              >
                <FaInstagram color="#c13584" size={50} />
              </a>
              {/* Email link opens the default email client */}
              <a
                href="mailto:info@hotice.com"
                className="social-icon"
                aria-label="Email"
              >
                <FaEnvelope color="#ffffff" size={50} />
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center py-3">
            <div className="contact-info mt-md-0 mt-3">
              <p>Contact Us:</p>
              <p>Phone: +1 (514) 927-6292</p>
              {/* Email as a clickable link */}
              <p>
                Email: <a href="mailto:info@hoticeclothing.com">info@hoticeclothing.com</a>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center py-3">
            <p>&copy; {currentYear} Hot Ice</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
