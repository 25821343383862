import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title:"Hot Ice Clothing",
  description:"Hot Ice Clothing: Your Premier Source for Men's Fashion Essentials. Explore our selection of top-quality hoodies, stretch boxers, classic boxers, and joggers available in both elastic and ribbed bottom styles. Discover a variety of T-shirts crafted from comfortable cotton and sweat-wicking polyester fabrics. Elevate your wardrobe with our stylish and comfortable clothing options.",
  keywords:"Hot Ice Clothing, men's fashion, hoodies, boxers, joggers, T-shirts",
};

export default Meta;
