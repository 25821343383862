import { createSlice } from '@reduxjs/toolkit';
import { updateCart } from '../utils/cartUtils';

const initialState = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : { cartItems: [], shippingAddress: {}, paymentMethod: 'PayPal' };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // NOTE: we don't need user, rating, numReviews or reviews
      // in the cart
      const { user, rating, numReviews, reviews, ...item } = action.payload;

      // const existItem = state.cartItems.find((x) => x._id === item._id);

      // if (existItem) {
      //   state.cartItems = state.cartItems.map((x) =>
      //     x._id === existItem._id ? item : x
      //   );
      // } else {
      //   state.cartItems = [...state.cartItems, item];
      // }

      const existingItemIndex = state.cartItems.findIndex(
        (x) => x._id === item._id && x.sizeOptions === item.sizeOptions
      );

      if (existingItemIndex !== -1) {
        // If the item with the same ID and sizeOptions already exists in the cart,
        // and you want to update its quantity, you can do so here
        state.cartItems[existingItemIndex].qty += item.qty;
      } else {
        // If the item is not in the cart, add it with the specified quantity and sizeOptions
        state.cartItems.push(item);
      }
      return updateCart(state, item);
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter((x) => x._id !== action.payload);
      return updateCart(state);
    },
    saveShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    saveSizeOptions: (state, action) => {
      state.sizeOptions = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCartItems: (state, action) => {
      state.cartItems = [];
      localStorage.setItem("cart", JSON.stringify(state));
    },
    // Add this new action
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    // NOTE: here we need to reset state for when a user logs out so the next
    // user doesn't inherit the previous users cart and shipping
    resetCart: (state) => (state = initialState),
  },
});

export const {
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
  saveSizeOptions,
  clearCartItems,
  resetCart,
  setCartItems,
} = cartSlice.actions;

export default cartSlice.reducer;
